import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'required'
})
export class RequiredPipe implements PipeTransform {

	/** Returns a visual indication determining if the field is required or not */
  transform(value: any, optional: boolean): any {
    return (optional ? '' : '*');
  }
}
