import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
	
	/** Transforms a raw 10-digit phone-number into a formatted variant in the 
	 * 	form of (xxx) xxx-xxxx.
	 * 			@param value {any} - The input
	 * 			@param param {string} - A parameter specifying which parameter it is. 
	 * 					This transformation would only apply to phone numbers. */
  transform(value: any, param: string): any {
    if (param === 'phone' || param === 'user_contact') {
			const s2: any = ('' + value).replace(/\D/g, '');
    	const m: any = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    	return (!m) ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
		}
		return value;
  }

}
