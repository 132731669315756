import { AdminService } from "../../../../api/services/admin.service";
import { AuthenticationService } from "../../../../api/services/authentication.service";
import { FormValidatorService } from "../../services/form-validator.service";
import { Component, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { InputFileComponent } from '../input-file';

export const CUSTOM_INPUT_IMAGE_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputImageComponent),
	multi: true
};

@Component({
	selector: 'form-input-image',
	inputs: ['param: param', 'force: force', 'admin: admin', 'noLabel: noLabel', 'optional: optional', 'ioAdmin: ioAdmin'],
	template: `<div class = "form-spacing" *ngIf = "canShowAsAdmin ()">
								<div class = "row">
									<!-- Form container -->
									<div class = "col-md-6">
										<label *ngIf = "!noLabel"><ng-content></ng-content><span class = "required">{{'' | required: optional }}</span> </label>
										<input type = "file" id = {{param}} [(ngModel)] = "value" class = "form-control-file" (change) = "readUrl($event)" (blur) = "onBlur()"
                       aria-describedby = "param-help" accept = {{extensions}} required>
										<small id="param-help" class="form-text text-muted">{{ description }}</small>
										<div [hidden] = "validator.isSuccessful(param)" class = "help-block">
												{{ validator.getMessage(param) }}
										</div>
									</div>
									<!-- Image Container -->
									<div *ngIf = "url !== ''" class = "col-md-6 mt-2 px-2">
										<img class = "img-embed ml-2" [src] = "url"> <br />
										<span class = "ml-2 mt-4">Image Preview</span>
									</div>
								</div>
							</div>`,
	styleUrls: ['../form-element.css'],
	providers: [CUSTOM_INPUT_IMAGE_CONTROL_VALUE_ACCESSOR]
})

export class InputImageComponent extends InputFileComponent {

	private url;
  constructor(private va: FormValidatorService,
		private au: AuthenticationService,
		private as: AdminService) {
			super(va, au, as);
	}

  ngOnInit() {
		super.ngOnInit();
  }

	/** Extracts the image file url used for previewing the user's image */
	protected readUrl (event: any) {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();

			reader.onload = (event: ProgressEvent) => {
				this.url = (<FileReader>event.target).result;
			}

			reader.readAsDataURL (event.target.files[0]);
		}
	}

}
