import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'app/api/services/authentication.service';
import { UserService } from 'app/api/services/user.service';
import { Subscription, Observable } from 'rxjs';
import { RemoteDataService } from 'app/services/remote-data.service';
import { catchError, reduce } from 'rxjs/operators';
import * as moment from 'moment';


@Component({
  templateUrl: './snapshot-package.component.html',
  styleUrls: ['./snapshot-package.component.scss']
})
export class SnapshotPackageComponent implements OnInit {
  vehicle_id: any;
  @Input()
  vehicle_number: any;
  sub$: Subscription;
  selectedDate: moment.Moment;
  datas: any;
  constructor(
    public dialogRef: MatDialogRef<SnapshotPackageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private userService: UserService,
    private dataService: RemoteDataService,
  ) {
  // here the received value is being assigned to class variable `x`
    this.vehicle_id = data.vehicle_id;
    this.vehicle_number = data.vehicle_number;
  }

  private userName;
  ngOnInit() {
    this.userName = this.authService.getUsername();
    this.userService.getUserByName (this.userName).subscribe (() => {
      this.sub$ = this.dataService.getVehicleSnapshotPackageById(this.vehicle_id).pipe(
        catchError(() => new Observable()),
        reduce((pre, cur) => [...pre, ...cur] )
      )
      .subscribe(datas => {
        this.datas = datas;
      });
    });
  }
}
