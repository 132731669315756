import {Injectable} from '@angular/core';

import * as Validators from '../util/validators';
import {ValidationResponse} from '../models/validation-response';
import {UserService} from '../../../api/services/user.service';
import {UserParamPipe} from '../pipes/user-param/user-param.pipe';
import * as UserParam from '../models/user-parameters';
import {FleetService} from "../../../api/services/fleet.service";

@Injectable({
	providedIn: 'root'
})

export class FormValidatorService {
	// Hash table of all of validation messages
	private errorTable: Map<string, ValidationResponse>;
	// Keeps track of the amount of API transactions currently active
	private transactionCount: number;
	// Flag used to determine if this component is attempt to connect with a service
	private connectionFlag: boolean;

	/** Constructor */
	constructor(private userService: UserService,
		private paramPipe: UserParamPipe,
		private fleetService: FleetService) {
		// Initializes the error table with empty validation objects'
		this.errorTable = new Map<string, ValidationResponse>();
		this.init();
	}

	/** Init. the error table */
	public init(): void {
		UserParam.userParameterList.forEach(userParam => {
			this.errorTable[userParam.param] = Validators.constructResponseObject();
		});
	}

	/** Validates a field component
   *    @param param {string} - The user parameter type being checked. */
	public validateComponent(param: string, value: any) {
		let responseObject: ValidationResponse = Validators.constructResponseObject();

		// Preserves certain response object flags
		const activeState: boolean = this.errorTable[param].active;
		const optionalState: boolean = this.errorTable[param].optional;
		const fileState: boolean = this.errorTable[param].isFile;

		// Checks if the value is empty
		if (Validators.checkEmptyValue(value)) {
			if (optionalState) {
				responseObject.success = true;
			}
			else {
				responseObject.error = this.paramPipe.transform(param) + ' required.';
			}

			// Restores flags
			responseObject.active = activeState;
			responseObject.optional = optionalState;
			responseObject.isFile = fileState;

			this.errorTable[param] = responseObject;
			return responseObject.success;
		}

		// Uses the parameter's validation routine, if it exists
		const paramObj: UserParam.UserParameters = UserParam.getUserParameter(param);
		if (paramObj.validator) {
			// Checks if this parameter requires file-validation
			const extensions = UserParam.getExtensions(param);
			if (extensions) {
				responseObject = paramObj.validator(value, extensions);
			}
			else {
				responseObject = paramObj.validator(value, this.paramPipe.transform(param));
			}
		}
		else {
			responseObject.success = true;
		}

		// Does special checks for any validation routine that has a dependency to this service's error table
		if (responseObject.success) {
			switch (param) {
				/* Password confirm field */
				case 'passwordConfirm':
					{
						// If the initial password check passes, then compare this password with the one from the original password field
						const pass = this.errorTable['password'].data;
						responseObject.success = (value === pass);
						if (!responseObject.success) {
							responseObject.error = "Passwords do not match."
						}
						break;
					}
				default:
					{
						break;
					}
			}
		}

		// Restores flags
		responseObject.optional = optionalState;
		responseObject.active = activeState;
		responseObject.isFile = fileState;

		if (!fileState) {
			responseObject.data = value;
		}

		this.errorTable[param] = responseObject;
		return responseObject.success;
	}

	/** Validates a username by checking it with the server for existence
	 * 		@param {string} value - The username being validated
	 * 		@return a response object containing the status of this request */
	public validateUser(value: string): void {
		let responseObject: ValidationResponse = Validators.constructResponseObject();
		const activeState: boolean = this.errorTable['username'].active;

		// Only verify with the servers once there are no active connections being made.
		if (!this.connectionFlag) {
			++this.transactionCount;
			this.connectionFlag = true;
			/* Checks if the provided username already exists in the database  */
			this.userService.getUserByName(value.toLowerCase()).subscribe(response => {
				try {
					responseObject.success = response['status'] === 404;
					if (!responseObject.success) {
						responseObject.error = 'User \"' + value.toLowerCase() + '\" already exists!';
					}
				}
				// Catches any error thrown here
				catch (error) {
					responseObject.error = 'There was an error trying to verify this username with the server.';
					responseObject.success = false;
					this.connectionFlag = false;
				}

				finally {
					--this.transactionCount;
					this.connectionFlag = false;
					responseObject.active = activeState;
					this.errorTable['username'] = responseObject;
				}
			});
		}
	}

	/** Validates a fleet ID by checking it with the server for existence
	 * 		@param {number} id - The fleet ID being validated
	 * 		@return a response object containing the status of this request */
	public validateFleetID(value: number): void {
		let responseObject: ValidationResponse = Validators.constructResponseObject();
		const activeState: boolean = this.errorTable['fleet_id'].active;

		// Only verify with the servers once there are no active connections being made.
		if (!this.connectionFlag) {
			++this.transactionCount;
			this.connectionFlag = true;
			/* Checks if the provided username already exists in the database  */
			this.fleetService.getFleetById(value).subscribe(response => {
				try {
					responseObject.success = (response['message']);
					if (!responseObject.success) {
						responseObject.error = 'Fleet ' + value + ' already exists!';
					}
				}
				// Catches any error thrown here
				catch (error) {
					responseObject.error = 'There was an error trying to verify this fleet ID with the server.';
					responseObject.success = false;
					this.connectionFlag = false;
				}

				finally {
					--this.transactionCount;
					this.connectionFlag = false;
					responseObject.active = activeState;
					this.errorTable['fleet_id'] = responseObject;
				}
			});
		}
	}

	/** @return the error message of the element with the assigned parameter */
	public getMessage(param: string) {
		return this.errorTable[param].error;
	}

	/** @return the success status of the element with the assigned parameter */
	public isSuccessful(param: string) {
		return this.errorTable[param].success;
	}

	/** Makes this component active */
	public makeActive(param: string): void {
		this.errorTable[param].active = true;
	}

	/** Deactivates a component and resets its data */
	public makeInactive(param: string): void {
		this.errorTable[param] = Validators.constructResponseObject();
	}

	/** Marks this component as a file input field */
	public markAsFileInput(param: string): void {
		this.errorTable[param].isFile = true;
	}

	/** Makes this component optional */
	public makeOptional(param: string): void {
		this.errorTable[param].optional = true;
		// By making this field optional, then this component is valid if it's input is blank
		this.errorTable[param].success = Validators.checkEmptyValue(this.errorTable[param].data);
	}

	/** @return true if all active components are validated */
	public isValid() {
		return UserParam.userParameterList.every(entity => {
			return (this.errorTable[entity.param].active) ? this.errorTable[entity.param].success : true;
		});
	}

	/** @return the data tied to the parameter. 
	 *  Note: for any input-fields that takes either file or image
	 *  input (form-input-file / form-input-image), this function would 
	 *  return that field data as a blob object, which is going to be useful when you are
	 *  transmitting the client's file to the server. */
	public getData(param: string) {
		return this.errorTable[param].data;
	}

	/** Resets the error table */
	public cleanup(): void {
		this.errorTable.clear();
		this.init();
	}
}

