import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
	
	/** Transforms a database address to a human-readable format
	 * 	  	@param value {any} - The input
	 * 			@param param {string} - A parameter specifying which parameter it is. 
	 * 					This transformation would only apply to addresses.*/
  transform(value: any, param: string): any {
    if (param !== 'address') {
			return value;
		}
		const tokens = value.split(',');
		// Format: {Street} {City}, {State} {Zip}
		return (tokens.length === 4) ? tokens[0] + ' ' + tokens[1] + ', ' + tokens[2] + ' ' + tokens[3] : value;
  }

}
