import {ValidationResponse} from "../models/validation-response";

/** REGULAR EXPRESSIONS / CONSTANTS */
// Regular expression used to validate username
export const userRegex: any = /^[a-zA-Z][a-zA-Z0-9_]+$/;
// Regular expression used to validate email
export const emailRegex: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Regular expression used to validate the phone number
export const phoneRegex: any = /^[0-9]{10,10}$/;
// Regular expression used to validate the password
export const passRegex: any = /^[a-zA-Z0-9!@#$%^&*()~`_+=|:;"'<,>.?\-\{\}\[\]\\]+$/;
// Regular expression used to validate a zip code
export const zipRegex: any = /^[0-9]{5,5}$/;
// Regular expression used to validate a number
export const numberRegex: any = /^[0-9]+$/;
// Regular expression used to validate a string
export const stringRegex: any = /^[a-zA-Z_\-.0-9 ]+$/;
// Regular expression used to validate a vdl version
export const vdlVersionRegex: any = /^[0-9][0-9][_][0-9][0-9][_][0-9][0-9][0-9][0-9]$/;
// Min. length of the username
export const minUsernameLength: number = 4;
// Min. length needed for the password
export const minPasswordLength: number = 8;
// Max length for strings
export const maxStringLength: number = 40;
// Max length for usernames
export const maxUsernameLength: number = 24;
// Max size (in MB) of files
export const maxFilesize: number = 4;
// List of allowed filetypes for images
export const imgFileTypes: Array<string> = [
	'png', 'bmp', 'jpg', 'jpeg'
];
// List of allowed filetypes for fleet project files
export const projectFileTypes: Array<string> = [
	'prj'
]

/** UTIL. FUNCTIONS */

/** A helper function to check if a string is empty */
export function checkEmptyValue(val: any) {
	return (val === null || val === undefined || !val);
}

/** A helper function that constructs an empty response object */
export function constructResponseObject() {
	return {success: false, error: '', active: false, data: "", optional: false, isFile: false};
}

/** VALIDATION ROUTINES 
 *  IMPORTANT NOTE WHEN MODIFYING: Make sure to always return a ValidationResponse object, and if any error occurs,
 * 	set that object's success flag to true to let the service know that something has gone wrong, with an
 *  error message associated with it. The error message goes into that object's error attribute).
 * 
 *  You could also set the args parameter to anything if you want to add a specific aux. argument for any validation
 *  routine you are using. By default, args is always set to the user-parameter's label attribute to construct generic error messages.
 *  However, this could be adjusted to fit specific use cases by modifying the code in line 68 of form-validator.service to add custom aux. parameters
 *  for a specific user parameter object.
 * 
 *  Do always follow the general style of how I do things before creating your own ways. Thank you for understanding!
 * 
 * ~ Eric Su
 */ 

/** Validates a username 
 * @param {string} value - The username being validated
 * @return a response object containing the status of request */
export function validateUser(value: string, args?: any) {
	let responseObject: ValidationResponse = constructResponseObject();
	
	if (value.length < minUsernameLength) {
		responseObject.error = 'Username must be at least ' + minUsernameLength + ' characters long.';
		responseObject.success = false;
		return responseObject;
	}
	
	if (value.length > maxUsernameLength) {
		responseObject.error = 'Username cannot exceed ' + maxUsernameLength + ' characters.';
		responseObject.success = false;
		return responseObject;
	}

	responseObject.success = userRegex.test(value);

	// Displays an error if the test fails
	if (!responseObject.success) {
		responseObject.error = "Username must not contain invalid characters.";
	}

	return responseObject;
}

/** Validates a string expression
 * @param {string} value - The name to be validated
 * @return a response object containing the status of request */
export function validateString(value: string, args?: any) {
	let responseObject: ValidationResponse = constructResponseObject();
	
  if (value.length > maxStringLength) {
		responseObject.error = args + ' cannot exceed ' + maxStringLength + ' characters.';
		responseObject.success = false;
		return responseObject;
	}
	
	responseObject.success = stringRegex.test(value);
	
	if (!responseObject.success) {
		responseObject.error =  args + ' cannot have invalid characters.';
	}
	
	return responseObject;
}


/** Validates an email expression
 * @param {string} value - the email to be validated
 * @return a response object containing the status of request */
export function validateEmail(value: string, args?: any) {
	let responseObject: ValidationResponse = constructResponseObject();
	// Checks if the email is written in the proper format
	responseObject.success = emailRegex.test(value);

	if (!responseObject.success) {
		responseObject.error = 'Please enter a valid email address.';
	}

	return responseObject;
}

/** Validates a phone number
 * @param {string} value - the phone number (as a raw string) to be validated
 * @return a response object containing the status of request */
export function validatePhone(value: string, args?: any) {
	let responseObject: ValidationResponse = constructResponseObject();

	// Extracts the raw phone number value from the field
	const rawValue = ('' + value).replace(/\D/g, '');

	// Checks to see if the phone number is valid
	responseObject.success = phoneRegex.test(rawValue);

	// If not, then display an error message
	if (!responseObject.success) {
		responseObject.error = 'Please enter a valid phone number.';
	}

	return responseObject;
}

/** Validates the given password based on the given password policy.
 * @param {string} value - The password to be validated
 * @return A response object containing the status of request. */
export function validatePassword(value: string, args?: any) {
	let responseObject: ValidationResponse = constructResponseObject();

	// Checks if the password's length satisfies the min. amount
	if (value.length < minPasswordLength) {
		responseObject.error = 'Password must be at least ' + minPasswordLength + ' characters long';
	}
	// Otherwise, evaluate the password with the defined regular expression
	else {
		responseObject.success = passRegex.test(value);

		if (!responseObject.success) {
			responseObject.error = 'Password must not contain invalid characters.';
		}
	}

	return responseObject;
}

/** Validates a zip code
 * @param {string} value - The zip code being validated
 * @return A response object containing the status of request. */
export function validateZip(value: any, args?: any) {
	let responseObject: ValidationResponse = constructResponseObject();

	responseObject.success = zipRegex.test(value);
	if (!responseObject.success) {
		responseObject.error = 'Please enter a valid zip code';
	}

	return responseObject;
}

/** Validates a generic number */
export function validateNumber(value: any, args?: any) {
	let responseObject: ValidationResponse = constructResponseObject();
	responseObject.success = numberRegex.test(value);

	if (!responseObject.success) {
		responseObject.error = 'Please enter a valid number';
	}

	return responseObject;
}

/** Validates a generic file * 
 *	@param value {any} - The file address being validated
 * 	@param args {Array<string>} - A list of allowed file extensions for this file, 
 *                                  which will be used to check if the inputed file is of that type. * 
 */
export function validateFile (value: any, args?: any) {
	let responseObject: ValidationResponse = constructResponseObject();
	
	// Retrieves the input event from the DOM and validates the bytes
	let file = (<HTMLInputElement>event.target).files;
	let fileSize: number;
	
	// Checks the size of the file, if it exists
	if (file && file[0]) {
		fileSize = (file[0].size / 1024 / 1024);
				
		responseObject.success = (fileSize <= maxFilesize);
		if (!responseObject.success) {
			responseObject.error = 'File size cannot exceed more than ' + maxFilesize + ' MB.';
			return responseObject;
		}
		
		// Appends the raw file (as a blob) to the response's data
		const rawFile = file[0];
		responseObject.data = new Blob ([rawFile]);
	}
	else {
		responseObject.success = false;
		responseObject.error = 'Inputted file is corrupt';
		return responseObject;
	}
	
	// Validates file extensions (iff parameters exist)
	if (args instanceof Array) {
		const argsWrapper: Array<string> = args;
		// First validate the extensions
		const addressTokens = value.split('.');
		responseObject.success = (argsWrapper.includes(addressTokens[addressTokens.length - 1].toLowerCase()));
			
		if (!responseObject.success) {
			responseObject.error = 'Invalid file type. Supported file types are ' + argsWrapper.toString().replace (new RegExp (',', 'g'), ', ');
		}
	}
	return responseObject;
}

/** Validates a vdl version expression
 * @param {string} value - The name to be validated
 * @return a response object containing the status of request */
export function validateVdlVersion(value: string, args?: any) {

    let responseObject: ValidationResponse = constructResponseObject();

    if (value.length > maxStringLength) {
        responseObject.error = args + ' cannot exceed ' + maxStringLength + ' characters.';
        responseObject.success = false;
        return responseObject;
    }

    responseObject.success = vdlVersionRegex.test(value);

    if (!responseObject.success) {
        responseObject.error =  args + ' format must be XX_XX_XXXX.';
    }

    return responseObject;
}