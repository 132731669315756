import {Component, OnInit, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

import {AuthenticationService, AdminService} from "../../../../api/services";
import {FormValidatorService} from "../../services/form-validator.service";
import {InputComponent} from "../input";

export const CUSTOM_INPUT_NUMBER_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputNumberComponent),
	multi: true
};

@Component({
	selector: 'form-input-number',
	inputs: ['param: param', 'ignoreUser: ignoreUser', 'force: force', 'optional: optional', 'admin: admin', 'placeholder: placeholder', 'noLabel: noLabel',
					 'checkServer: checkServer', 'autocomplete: autocomplete', 'ioAdmin: ioAdmin'],
	template: `<div class = "form-spacing" *ngIf = "canShowAsAdmin ()">
								<label *ngIf = "!noLabel"><ng-content></ng-content><span class = "required">{{'' | required: optional }}</span></label>
								<input type = "number" [(ngModel)] = "value" id = {{param}} class = "form-control" (blur) = "onBlur()" [placeholder] = "placeholder"
                               aria-describedby = "param-help" autocomplete = {{autocomplete}} required>
								<small id="param-help" class="form-text text-muted">{{ description }}</small>
								<div [hidden] = "validator.isSuccessful (param)" class = "help-block">
										{{ validator.getMessage(param) }}
								</div>
							</div>`,
	styleUrls: ['../form-element.css'],
	providers: [CUSTOM_INPUT_NUMBER_CONTROL_VALUE_ACCESSOR]
})
export class InputNumberComponent extends InputComponent {
	// If set to true, then this component would also validate with the server for certain components
	protected checkServer: boolean = false;
	
	constructor(private v: FormValidatorService,
		private a: AuthenticationService,
		private ad: AdminService) {
		super(v, a, ad);
	}

	ngOnInit() {
		super.ngOnInit();
	}
	
	/** Overridden validate function to support validating the fleet id */
	protected validate(): void {
		super.validate();
		
		if (this.checkServer && this.v.isSuccessful (this.param)) {
			// Validates the Fleet ID with the server
			if (this.param === 'fleet_id') {
				this.v.validateFleetID(<number>this.value);
			}
		}
	}

}
