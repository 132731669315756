import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DataSourceType, environment } from '../../environments/environment';
import {
    Preventive_Notification_Setting_Item
} from '../api/models/preventive-_notification-_setting-_item';
import { AlertService, FleetService, UserService, VehicleService } from '../api/services';

@Injectable({
  providedIn: 'root',
})
export class RemoteDataService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private fleetService: FleetService,
    private vehicleService: VehicleService,
    private alertService: AlertService
  ) {
    this.sourceType = environment.dataSource;

    switch (this.sourceType) {
      case DataSourceType.Firebase:
        this.rootUrl = environment.firebase.databaseURL;
        break;
      default:
        this.delayEmulatorTimer = environment.delayEmulatorTimer;
        this.rootUrl = environment.apiRootLocal;
        break;
    }
  }

  private sourceType: DataSourceType;
  private rootUrl: string;
  private delayEmulatorTimer = 3000;

  getMCCById(vehicleId: number): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(this.vehicleService.getVehicleMCCById(vehicleId));
    } else {
      return this.http
        .get<any>(`${this.rootUrl}/vehicle/MCC/${vehicleId}.json`)
        .pipe(delay(this.delayEmulatorTimer));
    }
  }

  getFleetPrj(fleetId: number): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(this.fleetService.getFleetPrjByID(fleetId));
    } else {
      console.log('prj data else');
      //return this.http.get<any>(`${this.rootUrl}/user/notification/${userName}.json`).pipe(delay(this.delayEmulatorTimer));
    }
  }

  getFleetVdl(fleetId: number): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(this.fleetService.getFleetVdlByID(fleetId));
    } else {
      console.log('vdl data else');
      //return this.http.get<any>(`${this.rootUrl}/user/notification/${userName}.json`).pipe(delay(this.delayEmulatorTimer));
    }
  }

  getFleetVdlApp(fleetId: number): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(this.fleetService.getFleetVdlAppByID(fleetId));
    } else {
      console.log("vdl app else")
      //return this.http.get<any>(`${this.rootUrl}/user/notification/${userName}.json`).pipe(delay(this.delayEmulatorTimer));
    }
  }

  getFleetDataDefinition(fleetId: number,itemType:string): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(
        this.fleetService.getFleetDataByIDAndItemType(fleetId, itemType)
      );
    } else {
      console.log('fleet data def else');
      //return this.http.get<any>(`${this.rootUrl}/user/notification/${userName}.json`).pipe(delay(this.delayEmulatorTimer));
    }
  }

  getUserNotification(userName: string): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(this.userService.getUserNotification(userName));
    } else {
      return this.http
        .get<any>(`${this.rootUrl}/user/notification/${userName}.json`)
        .pipe(delay(this.delayEmulatorTimer));
    }
  }

  getFleetById(fleetId: number): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(this.fleetService.getFleetById(fleetId));
    } else {
      return this.http.get<any>(`${this.rootUrl}/fleet/${fleetId}.json`);
    }
  }

  getFleetConnectionById(fleetId: number): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(this.fleetService.getFleetConnectionById(fleetId));
    } else {
      return this.http.get<any>(
        `${this.rootUrl}/admin/config/connectionhistory/${fleetId}.json`
      );
    }
  }

  getVehicleSnapshotPackageById(vehicleId: number): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(
        this.fleetService.getVehicleSnapshotPackageById(vehicleId)
      );
    } else {
      return this.http.get<any>(
        `${this.rootUrl}/admin/config/vehiclepkgdata/${vehicleId}/10.json`
      );
    }
  }

  getVehicleRequestSnapshotById(
    vehicleId: number,
    userName: string
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(
        this.vehicleService.getVehicleSnapshotRequest(vehicleId, userName)
      );
    } else {
      return this.http.get<any>(`${this.rootUrl}/${vehicleId}.json`);
    }
  }

  getVehicleById(vehicleId: number): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(this.vehicleService.getVehicleById(vehicleId));
    } else {
      return this.http.get<any>(`${this.rootUrl}/vehicle/${vehicleId}.json`);
    }
  }

  // data-log snapshot api
  getVehicleSnapshotByDataId(
    vehicleId: number,
    dataId: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(
        this.vehicleService.getVehicleSnapshotByDataId(vehicleId, dataId)
      );
    } else {
      return this.http.get<any>(
        `${this.rootUrl}/vehicle/${vehicleId}/status/${dataId}.json`
      );
    }
  }

  getPreventiveMaintNotifInfo(
    preventiveItemId: number,
    vehicleId: number,
    username: string
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      const params: VehicleService.GetPreventiveMaintNotifInfoParams = {
        preventiveItemID: preventiveItemId,
        username: username,
        vehicleId: vehicleId,
      };
      return Object.assign(
        this.vehicleService.getPreventiveMaintNotifInfo(params)
      );
    } else {
      return this.http.get<any>(
        `${this.rootUrl}/vehicle/preventiveNotifItem/${vehicleId}.json`
      );
    }
  }

  getVehicleAlertSnapshotParams(
    username: string,
    alertId: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      const params: AlertService.GetVehicleAlertSnapshotParams = {
        username: username,
        alertId: alertId,
      };
      return Object.assign(this.alertService.getVehicleAlertSnapshot(params));
    } else {
      return this.http.get<any>(
        `${this.rootUrl}/vehicle/alert/${alertId}.json`
      );
    }
  }

  getVehicleMaintLogInfo(
    vehicleId: number,
    username: string,
    year?: number,
    month?: number,
    resultCount?: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      const params: VehicleService.GetVehicleMaintLogInfoParams = {
        vehicleId: vehicleId,
        username: username,
        year: year,
        month: month,
        resultCount: resultCount,
      };
      return Object.assign(this.vehicleService.getVehicleMaintLogInfo(params));
    } else {
      return this.http
        .get<any>(`${this.rootUrl}/vehicle/maintLogInfo/${vehicleId}.json`)
        .pipe(delay(this.delayEmulatorTimer));
    }
  }

  getVehicleRoutineLogFile(
    vehicleId: number,
    username: string,
    zipPkg?: string,
    year?: number,
    month?: number,
    resultCount?: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      const params: VehicleService.GetVehicleRoutineLogFileParams = {
        vehicleId: vehicleId,
        username: username,
        year: year,
        month: month,
        zipPkg: zipPkg as ZipPkg,
        resultCount: resultCount,
      };
      return Object.assign(
        this.vehicleService.getVehicleRoutineLogFile(params)
      );
    } else {
      return this.http.get<any>(
        `${this.rootUrl}/vehicle/routingLogFileInfo/${vehicleId}.json`
      );
    }
  }

  getVehicleDebugLogFile(
    vehicleId: number,
    username: string,
    zipPkg?: string,
    resultCount?: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      const params: VehicleService.GetVehicleDebugLogFileParams = {
        vehicleId: vehicleId,
        username: username,
        zipPkg: zipPkg as ZipPkg,
        resultCount: resultCount,
      };
      return Object.assign(this.vehicleService.getVehicleDebugLogFile(params));
    } else {
      return this.http.get<any>(
        `${this.rootUrl}/vehicle/debugLogFileInfo/${vehicleId}.json`
      );
    }
  }

  getVehicleMaintSetting(
    vehicleId: number,
    username: string,
    zipPkg?: string,
    resultCount?: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      const params: VehicleService.GetVehicleMaintSettingParams = {
        vehicleId: vehicleId,
        username: username,
      };
      return Object.assign(this.vehicleService.getVehicleMaintSetting(params));
    } else {
      return this.http.get<any>(
        `${this.rootUrl}/vehicle/preventiveNotifItemSetting/${vehicleId}.json`
      );
    }
  }

  getVehicleTripReport(
    vehicleId: number,
    username: string,
    fleetId: number,
    begin_date?: string,
    end_date?: string
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      const params: VehicleService.GetVehicleReportInfoParams = {
        vehicleId: vehicleId,
        username: username,
        fleetId: fleetId,
        begin_date: begin_date,
        end_date: end_date,
      };
      return Object.assign(this.vehicleService.getVehicleTripReport(params));
    } else {
      return this.http.get<any>(
        `${this.rootUrl}/vehicle/trip/${fleetId}/${vehicleId}.json`
      );
    }
  }

  postPreventiveMaintNotifInfo(
    preventiveItemId: number,
    vehicleId: number,
    username: string
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      const params: VehicleService.UpdatePreventiveNotifItemParams = {
        preventiveItemID: preventiveItemId,
        username: username,
        vehicleId: vehicleId,
        resetStatus: 'reset',
      };
      return Object.assign(
        this.vehicleService.updatePreventiveNotifItem(params)
      );
    } else {
      return this.http.post<any>(
        `${this.rootUrl}/vehicle/preventiveNotifItem/${vehicleId}`,
        {}
      );
    }
  }

  postVehicleAlertSnapshotParams(
    username: string,
    alertId: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      const params: VehicleService.UpdateVehicleAlertSnapshotParams = {
        username: username,
        alertId: alertId,
        ackStatus: 'acknowledged',
      };
      return Object.assign(
        this.vehicleService.updateVehicleAlertSnapshot(params)
      );
    } else {
      return this.http.post<any>(
        `${this.rootUrl}/vehicle/alert/${alertId}`,
        {}
      );
    }
  }

  getVdlHeartbeatDecodeMsg(
    fleetId: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
       return Object.assign(this.vehicleService.getVdlHeartbeatDecodeMsgByFleetId(fleetId));
    } else {
       return this.http.get<any>(
         `${this.rootUrl}/vehicle/vdl/decodeStatusList/${fleetId}`
       );
    }
  }

  getVdlHeartbeatDecodeMsgByInfoMsgId(
    info_msg_id: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
       return Object.assign(this.vehicleService.getVdlHeartbeatDecodeMsgByInfoMsgId(info_msg_id));
    } else {
       return this.http.get<any>(
         `${this.rootUrl}/vehicle/vdl/decodeStatus/${info_msg_id}`
       );
    }
  }

  getVdlHeartbeatDecodeHistoryByDeviceId(
    device_id: string,
    begin_time: string,
    end_time: string,
    count: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
       return Object.assign(this.vehicleService.getVdlHeartbeatDecodeHistoryByDeviceId(device_id, begin_time, end_time, count));
    } else {
       return this.http.get<any>(
         `${this.rootUrl}/vehicle/vdl/decodeHeartbeatHistory/${device_id}/${begin_time}/${end_time}`
       );
    }
  }


  getVdlAlertMsgList(
    fleetId: number,
    startTime: string,
    endTime: string,
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
       return Object.assign(this.vehicleService.getVdlAlertMsgList(fleetId, startTime, endTime));
    } else {
       return this.http.get<any>(
         `${this.rootUrl}/vehicle/vdl/alertMsgList/${fleetId}/${startTime}/${endTime}`
       );
    }
  }

  getVdlAlertMsgStatusById(
    msgId: number,
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
       return Object.assign(this.vehicleService.getVdlAlertMsgStatusById(msgId));
    } else {
       return this.http.get<any>(
         `${this.rootUrl}/vehicle/vdl/alertDecodeMsg/${msgId}`
       );
    }
  }


  getVdlLatestRecordFileList(
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      return Object.assign(this.vehicleService.getVdlLatestRecordFileList());
   } else {
      return this.http.get<any>(
        `${this.rootUrl}/vehicle/vdl/latestRecordFileList`
      );
   }
  }

  getVdlFile(file_id: string) {
    if (this.sourceType === DataSourceType.Swagger) {
        return Object.assign(this.vehicleService.getVdlFile(file_id));
    } else {
        return this.http.get<any>(
          `${this.rootUrl}/vehicle/vdlfile/${file_id}`
        );
    }
  }

  getVdlHistoryRecordFileList(
    vin_number: string,
    count?: number
  ): Observable<any> {
    if (this.sourceType === DataSourceType.Swagger) {
      const params: VehicleService.GetVdlHistoryRecordFileListParams = {
            vin_number: vin_number,
            count: count
      };
      return Object.assign(this.vehicleService.getVdlHistoryRecordFileList(params));
    } else {
        return this.http.get<any>(
          `${this.rootUrl}/vehicle/vdl/RecordFileHistory/${vin_number}`
        );
    }
  }

  getVdlLatestErrorMsg(
     user_name: string
  ): Observable<any> {
      if (this.sourceType === DataSourceType.Swagger) {
          return Object.assign(this.vehicleService.getVdlLatestErrorMsg(user_name));
      } else {
          return this.http.get<any>(            
            `${this.rootUrl}/vehicle/vdl/errorMsg/latest/${user_name}`      
          );
      }    
  }
  
  acknowledgeVdlError(
    device_id: string,
    msg_time: string
  ): Observable<any> {
     if (this.sourceType === DataSourceType.Swagger) {
         return Object.assign(this.vehicleService.acknowledgeVdlError(device_id,msg_time));
     } else {
         return this.http.get<any>(            
           `${this.rootUrl}/vehicle/vdl/acknowledgeError/${device_id}/${msg_time}`
         );
     }    
  }


}

export type ZipPkg = 'yes' | 'no';
