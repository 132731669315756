/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Fleet } from '../models/fleet';
import { AuthenticationService } from "./authentication.service";
@Injectable()
class ApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
		private authService: AuthenticationService
  ) {
    super(config, http);
  }

  /**
   * @param body Fleet object that needs to be added to the System
   */
  addFleetResponse(body: Fleet): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fleet/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body Fleet object that needs to be added to the System
   */
  addFleet(body: Fleet): Observable<void> {
    return this.addFleetResponse(body).pipe(
      map(_r => _r.body)
    );
  }
	
  /** Overrides the newParams function to include the user's API token */
	public newParams () {
		return super.newParams().set (
			'cats_api_key', this.authService.getSessionToken()
		);
	}
}

module ApiService {
}

export { ApiService }