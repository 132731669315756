/* Conversion constants */
export const KM_CNST = 1.609344;			// Miles to KM constant

export interface UnitConversion {
  unit: string;												// The type of unit being converted to (defaults to miles)
	conversion?: any;										// A function that converts n miles to a defined metric 
}

export const unitConversions: UnitConversion[] = [
	{unit: 'mi'},
	{unit: 'km', conversion: convertToKilometers}
];

/** Returns the UnitConversion object from the pre-defined list */
export function getUnitConversion (unit: string) {
	return unitConversions.find (entity => {
		return entity.unit === unit;
	});
}

/** Converts miles to kilometers */
export function convertToKilometers (value: number) {
	return value * KM_CNST;
}
