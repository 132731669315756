import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from "rxjs";

import * as UnitConversion from '../models/unit-conversion';

@Injectable({
  providedIn: 'root'
})
export class UnitConversionService {
	// Subject that keeps track of any changes made to the user's current conversion method
	private currentConversion: Subject <UnitConversion.UnitConversion>;
	// Keeps track of the current metric
	private conversion: UnitConversion.UnitConversion;
	
  constructor() { 
		this.conversion = UnitConversion.unitConversions[0];
		this.currentConversion = new BehaviorSubject <UnitConversion.UnitConversion>(this.conversion);
	}
	
	/** Returns the current conversion metric being used */
	public getCurrentConversion () {
		return this.conversion;
	}
	
	/** Returns the update listener as a subscription */
	public getCurrentConversionSubscription () {
		return this.currentConversion.asObservable();
	}
	
	/** Updates the unit conversion metric with a new value */
	public updateConversion (value: UnitConversion.UnitConversion): void {
		this.currentConversion.next (value);
		this.conversion = value;
	}
}
