import {Component, OnInit, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

import {InputComponent} from '../input/input.component';
import {FormValidatorService} from '../../services/form-validator.service';
import {AuthenticationService} from '../../../../api/services/authentication.service';
import {AdminService} from '../../../../api/services/admin.service';

export const CUSTOM_INPUT_PASSWORD_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputPasswordComponent),
	multi: true
};

@Component({
	selector: 'form-input-password',
	inputs: ['param: param', 'force: force', 'admin: admin', 'noLabel: noLabel', 'autocomplete: autocomplete', 'ioAdmin: ioAdmin'],
	template: `<div class = "form-spacing" *ngIf = "canShowAsAdmin ()">
								<label *ngIf = "!noLabel"><ng-content></ng-content><span class = "required">{{'' | required: optional }}</span> </label>
								<input type = "password" id = {{param}} [(ngModel)] = "value" class = "form-control" (blur) = "onBlur()"
                 aria-describedby = "param-help" autocomplete = {{autocomplete}} required>
								<small id="param-help" class="form-text text-muted">{{ description }}</small>
								<div [hidden] = "validator.isSuccessful(param)" class = "help-block">
										{{ validator.getMessage(param) }}
								</div>
							</div>`,
	styleUrls: ['../form-element.css'],
	providers: [CUSTOM_INPUT_PASSWORD_CONTROL_VALUE_ACCESSOR]
})

export class InputPasswordComponent extends InputComponent {

	constructor(private v: FormValidatorService,
		private a: AuthenticationService,
		private ad: AdminService) {
		super(v, a, ad);
	}

	ngOnInit() {
		super.ngOnInit();
	}

}
