import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
	// Capitalizes the first letter of any word
  transform(value: any, args?: any): any {
  		if (value) {
				return value.charAt(0).toUpperCase() + value.slice (1);
			}
			return value;
  }
}
