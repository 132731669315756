import { UnitConversionService } from "../../services/unit-conversion.service";
import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertUnits',
	pure: false
})
export class ConvertUnitsPipe implements PipeTransform {
	constructor (private conversionService: UnitConversionService,
							 private formatter : DecimalPipe) {}
	
  transform(value: any, args?: any): any {
		const conversionFunc = this.conversionService.getCurrentConversion().conversion;

		// 09042018 gng: Display NA when mileage data is zero.
		if (value == 0)
		{
			return 'N/A';
		}
		if (conversionFunc) {
			return this.formatter.transform (conversionFunc (value), '1.0-2') + ' ' + this.conversionService.getCurrentConversion().unit;
		}
		return this.formatter.transform (value, '1.0-2') + ' ' + this.conversionService.getCurrentConversion().unit;
  }
}
