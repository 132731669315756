import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../../api/services/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html'
})
export class AppSidebarComponent implements OnInit {
  constructor(private authService: AuthenticationService) {}
  private loggedFlag: boolean;

  ngOnInit() {
    this.isLoggedIn();
  }

  /** Checks the user's local storage to see if the user has their credentials stored in it.
   *  For now, having the user's credentials stored in localstorage is a "good-enough" indicator that the user
   *  is logged in. However, this is a highly insecure way and needs to be changed before going production ready. */
  isLoggedIn() {
    return this.authService.isLoggedIn();
  }
}
