import { Pipe, PipeTransform } from '@angular/core';
import * as UserParam from '../../models/user-parameters';
@Pipe({
  name: 'userParam'
})
	
/** Pipes a raw user-parameter name into it's assigned label. For example, the param
 *  'phone' transforms into 'Phone number'. This is used to easily create human-readable
 *  variants of the form labels. */ 
export class UserParamPipe implements PipeTransform {

  transform(value: any, args?: any): any {
	    const paramObj: UserParam.UserParameters = UserParam.getUserParameter (value);
			return (paramObj ? paramObj.label : value);
	}
}
