import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'possessive'
})
export class PossessivePipe implements PipeTransform {
	
	/** Enforces a possessive noun grammar rule that would pipe either an 's or ' depending 
	 *  if the last character of the input string is an s. 
	 			@param value {string} - The input */ 
  transform(value: string, args?: any): any {
    return ((value[value.length - 1] === 's') ? value + '\'' : value + '\'s');
  }

}
