import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applicable'
})
export class ApplicablePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    if (value === '' || value === null){
      return 'N/A';
    }


    return value;

  }

}
