import { Component, OnInit, forwardRef } from '@angular/core';

import { InputComponent } from "../input/input.component";
import { AdminService } from "../../../../api/services/admin.service";
import { AuthenticationService } from "../../../../api/services/authentication.service";
import { FormValidatorService } from "../../services/form-validator.service";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import * as UserParameters from '../../models/user-parameters';

export const CUSTOM_INPUT_FILE_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputFileComponent),
	multi: true
};

@Component({
	selector: 'form-input-file',
	inputs: ['param: param', 'force: force', 'admin: admin', 'noLabel: noLabel', 'optional: optional', 'ioAdmin: ioAdmin'],
	template: `<div class = "form-spacing" *ngIf = "canShowAsAdmin ()">
								<label *ngIf = "!noLabel"><ng-content></ng-content><span class = "required">{{'' | required: optional }}</span> </label>
								<input type = "file" id = {{param}} [(ngModel)] = "value" class = "form-control-file" (blur) = "onBlur()" 
                   aria-describedby = "param-help" accept = {{extensions}} required>
								<small id="param-help" class="form-text text-muted">{{ description }}</small>
								<div [hidden] = "validator.isSuccessful(param)" class = "help-block">
										{{ validator.getMessage(param) }}
								</div>
							</div>`,
	styleUrls: ['../form-element.css'],
	providers: [CUSTOM_INPUT_FILE_CONTROL_VALUE_ACCESSOR]
})
	
export class InputFileComponent extends InputComponent {
	// Stores extensions used for this file component
	private extensions: string = '';
	
  constructor(private v: FormValidatorService,
		private a: AuthenticationService,
		private ad: AdminService) {
		super(v, a, ad);
	}

  ngOnInit() {
		super.ngOnInit();
		this.v.markAsFileInput (this.param);
		if (this.description === '') {
			// Constructs the hint text
			this.description = UserParameters.generateFileHint (this.param);
			// Constructs the file extensions used for filtering in the dialogue box
			UserParameters.getExtensions(this.param).forEach (param => {
				this.extensions += '.' + param + ',';
			});
			// Slices last comma from the extensions string
			this.extensions = this.extensions.slice (0, this.extensions.length - 1);
		}
  }

}
