// Note: used base code found in 
// https://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
import {Component, OnInit, forwardRef, OnDestroy} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {fromEvent, interval, Subscription} from "rxjs";
import {ajax} from 'rxjs/ajax';
import {map, filter, debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";

import {FormValidatorService} from '../../services/form-validator.service';
import {AuthenticationService} from '../../../../api/services/authentication.service';
import {AdminService} from '../../../../api/services/admin.service';
import * as UserUtils from '../../models/user-parameters';

/* Placeholder function */
const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputComponent),
	multi: true
};

@Component({
	selector: 'form-input',
	inputs: ['param: param', 'ignoreUser: ignoreUser', 'force: force', 'optional: optional', 'admin: admin', 'placeholder: placeholder', 'noLabel: noLabel',
	         'autocomplete: autocomplete', 'ioAdmin: ioAdmin'],
	template: `<div class = "form-spacing" *ngIf = "canShowAsAdmin ()">
								<label *ngIf = "!noLabel"><ng-content></ng-content><span class = "required">{{'' | required: optional }}</span></label>
								<input [(ngModel)] = "value" id = {{param}} class = "form-control" (blur) = "onBlur()" aria-describedby = "param-help"
                       [placeholder] = "placeholder" autocomplete = {{autocomplete}} required>
								<small id="param-help" class="form-text text-muted">{{ description }}</small>
								<div [hidden] = "validator.isSuccessful (param)" class = "help-block">
										{{ validator.getMessage(param) }}
								</div>
							</div>`,
	styleUrls: ['../form-element.css'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputComponent implements ControlValueAccessor, OnInit {
	// Internal data model
	protected innerValue: any = '';
	// The parameter used 
	protected param: string;
	// Allows the username validation component to ignore a specific username
	protected ignoreUser: string;
	// If set to true, then the component would automatically be validated upon initialization
	protected force: boolean = false;
	// Determines if this field is optional. If so, then blank inputs are treated as valid input
	protected optional: boolean = false;
	// The (optional) description of this component
	protected description: string;
	// Optional placeholder text
	protected placeholder: string = '';
	// Determines if this component could only be accessible by administrators
	protected admin: boolean;
	// Determines if the label should be visible
	protected noLabel: boolean = false;
	// Autocomplete tag
	protected autocomplete: string = '';
	// Determines if this label should only be visible to superadmins
	protected ioAdmin: boolean = false;

	// Placeholder functions
	protected onTouchedCallback: () => void = noop;
	protected onChangeCallback: (_: any) => void = noop;

	constructor(protected validator: FormValidatorService,
		protected authService: AuthenticationService,
		protected adminService: AdminService) {}

	// Constructor
	ngOnInit() {
		// Only make this component active if this component passes the admin. check
		if (this.canShowAsAdmin()) {
			this.validator.makeActive(this.param);
		}
		// Sets the optional property of this component
		if (this.optional) {
			this.validator.makeOptional(this.param);
		}
		// Gets the autocomplete attribute, if it exists
		if (UserUtils.getUserParameter(this.param).autocomplete) {
			this.autocomplete = UserUtils.getUserParameter(this.param).autocomplete;
		}
		
		this.description = UserUtils.getHint(this.param);
	}

	ngOnDestroy() {
		this.validator.makeInactive(this.param);
	}

	/** @return the value inserted into this form element */
	get value(): any {
		return this.innerValue;
	}

	/** Sets the inner-value to another value */
	set value(v: any) {
		if (v !== this.innerValue) {
			this.innerValue = v;
			this.onChangeCallback(v);
		}
	}
	
	get valid(): any {
		return this.validator.isSuccessful (this.param);
	}

	/** Set touched on blur */
	onBlur() {
		this.validate();
		this.onTouchedCallback();
	}

	writeValue(value: any) {
		if (value !== this.innerValue) {
			this.innerValue = value;
			// Force validates this component if that flag is enabled
			if (this.force) {
				this.validate();
			}
		}
	}

	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	/** Validates this component */
	protected validate(): void {
		this.validator.validateComponent(this.param, this.innerValue);
		// Validates the username server-side
		if (this.param === 'username'
			&& this.validator.isSuccessful(this.param)
			&& !this.checkIgnoreUsernameState()) {
				this.validator.validateUser(<string>this.innerValue);
		}
	}

	/** @return true if the username check is going to be ignored in this special case */
	protected checkIgnoreUsernameState() {
		return ((this.ignoreUser) && (this.innerValue === this.ignoreUser));
	}

	/** Enforces the admin rule */
	protected canShowAsAdmin() {
		if (this.admin && !this.ioAdmin) {
			return this.adminService.isAdmin();
		}
		else if (!this.admin && this.ioAdmin) {
			return this.adminService.isIOAdmin();
		}
		return true;
	}
}
