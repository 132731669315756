import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router'; // @author Eric Su

// Import Containers
import { FullLayoutComponent } from './containers';

// Auth. guard
// @author Eric Su
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home', // @author Eric Su
    pathMatch: 'full'
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: {
      title: ''
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard] // @author Eric Su (redirects to login page if not logged in)
      },
      {
        path: 'home',
        //loadChildren: './views/home/home.module#HomeModule',
        loadChildren: './views/fleet/fleet.module#FleetModule',
        canActivate: [AuthGuard] // @author Eric Su (redirects to login page if not logged in)
      },
      {
        path: 'fleet',
        loadChildren: './views/fleet/fleet.module#FleetModule',
        canActivate: [AuthGuard] // @author Eric Su (redirects to login page if not logged in)
      },
      {
        path: 'alert-list',
        loadChildren: './views/alert-list/alert-list.module#AlertListModule',
        canActivate: [AuthGuard] // @author Eric Su (redirects to login page if not logged in)
      },
      {
        path:'healthMonitor',
        loadChildren: './views/health-monitor/health-monitor.module#HealthMonitorModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'pmn/:id',
        loadChildren: './views/pmn/pmn.module#PmnModule',
        canActivate: [AuthGuard] // @author Eric Su (redirects to login page if not logged in)
      },
      {
        path: 'datalog',
        loadChildren: './views/data-log/data-log.module#DataLogModule',
        data: {
          preload: true
        },
        canActivate: [AuthGuard] // @author Eric Su (redirects to login page if not logged in)
      },
      {
        path: 'vehicleReports',
        loadChildren:
          './views/vehicle-reports/vehicle-reports.module#MonthlyReportModule',
        canActivate: [AuthGuard] // @author Eric Su (redirects to login page if not logged in)
      },
      {
        path: 'vdlDashboard',
        loadChildren:
          './views/vdl-dashboard/vdl-dashboard.module#VdlDashboardModule',
        canActivate: [AuthGuard] // @author Eric Su (redirects to login page if not logged in)
      },
      {
        path: 'dataDownload',
        loadChildren:
          './views/data-download/data-download.module#DataDownloadModule',
        canActivate: [AuthGuard] // @author Eric Su (redirects to login page if not logged in)
      },
      // Login (@author Eric Su)
      {
        path: 'login',
        loadChildren: './views/login/login.module#LoginModule'
      },
      // User Profile (@author Eric Su)
      {
        path: 'user/:username',
        loadChildren: './views/profile/profile.module#ProfileModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
      },
      // Logout (@author Eric Su)
      {
        path: 'logout',
        loadChildren: './views/logout/logout.module#LogoutModule'
      },
      // Admin Features
      {
        path: 'admin',
        loadChildren:
          './views/admin-wrapper/admin-wrapper.module#AdminWrapperModule',
        canActivate: [AuthGuard]
      },
      // Forget password
      {
        path: 'forget',
        loadChildren:
          './views/forget-password/forget-password.module#ForgetPasswordModule'
      },
      // reset password
      {
        path: 'reset/:email/:token',
        loadChildren:
          './views/reset-password/reset-password.module#ResetPasswordModule'
      },
      {
        path: 'activatevehicle',
        loadChildren:
          './views/activate-vehicle/activate-vehicle.module#ActivateVehicleModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
