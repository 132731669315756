/* tslint:disable */
import { Injectable, EventEmitter } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Alert_Notification_Item } from '../models/alert-_notification-_item';
import { AuthenticationService } from "./authentication.service";

/**
 * Retrieve alerts from system.
 */
@Injectable()
class AlertService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
		private authService: AuthenticationService
  ) {
    super(config, http);
  }

  /**
   * Returns alert snapshot information (Req. 3-0)
   * @param params The `AlertService.GetVehicleAlertSnapshotParams` containing the following parameters:
   *
   * - `username`: Pass in username
   *
   * - `alertId`: Pass in alert id
   *
   * @return successful operation
   */
  getVehicleAlertSnapshotResponse(params: AlertService.GetVehicleAlertSnapshotParams): Observable<HttpResponse<Alert_Notification_Item>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.username != null) __params = __params.set('username', params.username.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/alert/${params.alertId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Alert_Notification_Item = null;
        _body = _resp.body as Alert_Notification_Item;
        return _resp.clone({body: _body}) as HttpResponse<Alert_Notification_Item>;
      })
    );
  }

  /**
   * Returns alert snapshot information (Req. 3-0)
   * @param params The `AlertService.GetVehicleAlertSnapshotParams` containing the following parameters:
   *
   * - `username`: Pass in username
   *
   * - `alertId`: Pass in alert id
   *
   * @return successful operation
   */
  getVehicleAlertSnapshot(params: AlertService.GetVehicleAlertSnapshotParams): Observable<Alert_Notification_Item> {
    return this.getVehicleAlertSnapshotResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /** Overrides the newParams function to include the user's API token */
	public newParams () {
		return super.newParams().set (
			'cats_api_key', this.authService.getSessionToken()
		);
	}


}

module AlertService {

  /**
   * Parameters for getVehicleAlertSnapshot
   */
  export interface GetVehicleAlertSnapshotParams {

    /**
     * Pass in username
     */
    username: string;

    /**
     * Pass in alert id
     */
    alertId: number;
  }
}

export { AlertService }
