import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Provider } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import './rxjs-extensions';

import { AppComponent } from './app.component';
import { RemoteDataService } from 'app/services/remote-data.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiConfiguration } from 'app/api/api-configuration';
import { ApiModule } from './api/api.module';
import { FormElementsModule } from './views/form-elements/form-elements.module';

// Import containers
import { FullLayoutComponent, SimpleLayoutComponent } from './containers';

const APP_CONTAINERS = [FullLayoutComponent, SimpleLayoutComponent];

// Import components
import {
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,
  AppHeaderAccountComponent
} from './components';

const APP_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,
  AppHeaderAccountComponent
];

// Import directives
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
} from './directives';

// Import pipes
import { AppPipeModule } from './pipes/pipes.module';

const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
];

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AgmCoreModule } from '@agm/core';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from 'environments/environment';

// JSON web token module
import { JwtModule } from '@auth0/angular-jwt';

// Interecptors (@author Eric Su)
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { ReverseGeocodeDirective } from './services/reverse-geocode.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { SnapshotPackageComponent } from './views/admin-wrapper/snapshot-package/snapshot-package.component';

export function initApiConfiguration(config: ApiConfiguration): Function {
  return () => {
    if (environment && environment.apiRootIocCATS) {
      config.rootUrl = environment.apiRootIocCATS;
    }
  };
}

export const INIT_API_CONFIGURATION: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initApiConfiguration,
  deps: [ApiConfiguration],
  multi: true
};

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    NgxDatatableModule,
    AgmCoreModule.forRoot(environment.agm),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AppPipeModule,
    ApiModule,
    FormElementsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('session-token');
        }
      }
    }),
    MatDialogModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
    ReverseGeocodeDirective,
    SnapshotPackageComponent
  ],
  entryComponents: [SnapshotPackageComponent],
  providers: [
    INIT_API_CONFIGURATION,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    RemoteDataService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
