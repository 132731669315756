/* @author Eric Su */
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, BehaviorSubject, Subscription, interval } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { catchError, map, tap, filter } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';
import { JwtModule } from '@auth0/angular-jwt';

import { BaseService } from '../base-service';
import { ApiResponse } from '../models/api-response';
import { AuthMessage } from '../models/auth/auth-message';
import { CatsApiToken } from '../models/auth/cats-api-token';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiForgetPwd } from '../models/api-forgetPwd';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseService implements OnDestroy {
  // Stores the address of the auth API
  private authAddress = this.rootUrl + '/user/login';
  // The string representing the local-storage ID for username
  private usernameID: string = 'username';
  // The string representing the local-storage ID for the login time-stamp
  private loginTimestampID: string = 'login-timestamp';
  // The string representing the local-storage ID for the session token
  private sessionTokenID: string = 'session-token';
  // The string representing the local-storage ID for the company name
  private companyNameID: string = 'company-name';
  // The number representing the local-storage ID for the default fleet ID
  private defaultFleetID: string = 'default-fleet';
  // List of local-storage ID's
  private localStorageID: string[] = [
    this.usernameID,
    this.loginTimestampID,
    this.sessionTokenID,
    this.companyNameID,
    this.defaultFleetID,
  ];
  // Subject used to distribute any authentication error messages throughout the system
  private authListener: BehaviorSubject<AuthMessage>;

  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private jwtHelper: JwtHelperService,
    public router: Router
  ) {
    super(config, http);

    // Initializes the listener with an empty message
    this.authListener = new BehaviorSubject<AuthMessage>({
      error: false,
      message: 'OK',
    });
  }

  ngOnDestroy() {}

  /** Authenticates the user by attempting to make a RESTful call to the CATS API
   *  to verify if the provided credentials are correct */
  public authenticate(
    username: string,
    password: string
  ): Observable<ApiResponse> {
    const formData = new FormData();
    formData.set('username', username);
    formData.set('password', password);
    return this.http.post<ApiResponse>(this.authAddress, formData).pipe(
      catchError(this.handleError('authentication')),
      tap((response) => {
        // Clears out any token validation errors upon successful login
        if (response && response['status'] === 200) {
          this.authListener.next({ error: false, message: 'OK' });
        }
      })
    );
  }

  /** @return the username of the user that is currently logged in */
  public getUsername() {
    const token: CatsApiToken = this.decodeJWT();
    return token ? token.user_token.user : null;
  }

  /** @return the timestamp from when the user has logged on */
  public getLoginTimestamp() {
    return window.localStorage.getItem(this.loginTimestampID);
  }

  /** @return the user's session token */
  public getSessionToken() {
    return window.localStorage.getItem(this.sessionTokenID);
  }

  /** @return the user's company name */
  public getCompanyName() {
    return window.localStorage.getItem(this.companyNameID);
  }

  /** @return the user's default fleet id */
  public getDefaultFleetId() {
    return window.localStorage.getItem(this.defaultFleetID);
  }
  /** Stores the user's username to localstorage
   * 		@param username {string} - The username that would be stored in the browser's local-storage */
  public storeUsername(username: string): void {
    window.localStorage.setItem(this.usernameID, username);
  }

  /** Stores the user's logged-in timestamp to local-storage
   * 		@param timestamp {Date} - The timestamp since the user last logged in as a data object */
  public storeTimestamp(timestamp: Date): void {
    window.localStorage.setItem(this.loginTimestampID, timestamp.toString());
  }

  /** Stores the user's session token */
  public storeSessionToken(token: string): void {
    window.localStorage.setItem(this.sessionTokenID, token);
  }

  /** Stores the user's company name */
  public storeCompanyName(name: string): void {
    window.localStorage.setItem(this.companyNameID, name);
  }

  /** Stores the user's company name */
  public storeDefaultFleetId(id: string): void {
    window.localStorage.setItem(this.defaultFleetID, id);
  }

  /** Removes the user's username credential from the browser's local-storage */
  public removeUsername(): void {
    window.localStorage.removeItem(this.usernameID);
  }

  /** Removes the user's logged-in time-stamp from the browser's local-storage */
  public removeTimestamp(): void {
    window.localStorage.removeItem(this.loginTimestampID);
  }

  /** Removes the user's session token */
  public removeSessionToken(): void {
    window.localStorage.removeItem(this.sessionTokenID);
  }

  /** Removes the user's company name */
  public removeCompanyName(): void {
    window.localStorage.removeItem(this.companyNameID);
  }

  /** Removes the user's default fleet id */
  public removeDefaultFleetId(): void {
    window.localStorage.removeItem(this.defaultFleetID);
  }

  /** Clears out all localstorage assets */
  public clearLocalStorage(): void {
    this.localStorageID.forEach((id) => {
      window.localStorage.removeItem(id);
    });
  }

  /** Determines if the user is currently logged in */
  public isLoggedIn() {
    return (
      !!this.getUsername() &&
      !!this.getLoginTimestamp() &&
      !!this.getSessionToken() &&
      !!this.getCompanyName() &&
      !!this.getDefaultFleetId()
    );
  }

  /** Returns this service's auth listener as an observable. The auth listener is used to transmit any authentication
   *  messages throughout the system. */
  public getAuthListener() {
    return this.authListener.asObservable();
  }

  /** Tells this service to throw a token authentication error when called.
   * 		@param message {string} - The error message describing the situation */
  public throwAuthError(message: string): void {
    this.authListener.next({ error: true, message: message });
  }

  /** Handles a failed HTTP operation. The app is designed to
   *  continue (and log) the error message.
   *    @param {string} operation - The name of the operation that failed
   *    @param {T} result - [Optional] value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  /** Decodes the JWT token and checks validates it's parameters
   * 		@return {CatsApiToken} the decoded token or null if any errors occur within the decoding process */
  private decodeJWT(): CatsApiToken {
    try {
      const decoded: CatsApiToken = this.jwtHelper.decodeToken(
        this.getSessionToken()
      );

      if (decoded) {
        // Validates the token's expiration date
        if (this.jwtHelper.isTokenExpired()) {
          this.throwAuthError('Session expired');
          return null;
        }
        // TODO: Validate the token with the server

        return decoded;
      }
    } catch (error) {
      this.throwAuthError('Invalid session token');
    }
    return null;
  }
}
