/* tslint:disable */

// @author Eric Su
import { ApiResponse } from './api-response';
import { UserGroup } from "./user-group";

// @author Eric Su
export interface User extends ApiResponse {
  id?: number;
  username?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  password?: string;
  phone?: string;
	user_type?: string;
	user_contact?: string;
	create_time?: string;
	modified_time?: string;
	address?: string;
	user_contact_id?: number;
	userStatus?: string;
	user_group?: Array<UserGroup>;
	user_token?: string;
	fleet_id?: number;
	carrier?: string;
}

/** A pre-defined list of possible user statuses */
export const userStatus: string[] = [
		'active',
		'inactive'
];

/** A pre-defined list of possible user group types */
export const userType: string[] = [
		'user',
		'admin'
]

/** List of phone carriers */
export const carriers: string [] = [
		'AT&T',
		'TMobile',
		'Sprint'
];

/** Checks if this user is part of the iocontrols_admin group, which has
 *  elevated privileges over everyone else */
export function isSuperAdmin (user: User) {
	let result: boolean = false;
	user.user_group.forEach (group => {
		result = result || (group.user_group_type === 'admin' && group.user_group_name === 'iocontrols_admin')
	});
	return result;
}