export const navigation = [
  {
    name: 'Home',
    url: '/home',
    icon: 'icon-home',
    badge: {
      variant: 'info',
      text: 'Start'
    }
  },
  {
    name: 'Fleet',
    url: '/fleet',
    icon: 'fa fa-bus'
  },
  {
    name: 'Alert',
    url: '/alert-list',
    icon: 'icon-bell'
  },
  {
    name: 'VDL Production',
    url: '/vdlDashboard',
    icon: 'fa fa-tachometer',
    admin: 'true'
  },
  {
    name: 'Health Monitor',
    url: '/healthMonitor',
    icon: 'fa fa-medkit'  
  },
  {
    name: 'Data Download',
    url: '/dataDownload',
    icon: 'fa fa-file'
  },
  // @author Eric Su
  {
    name: 'Admin Settings',
    url: '/admin/usermanagement',
    icon: 'fa fa-cogs',
    admin: 'true',

    children: [
      {
        // User Management
        name: 'User Management',
        url: '/admin/usermanagement',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        // Fleet Management
        name: 'Fleet Management',
        url: '/admin/fleetmanagement',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        // Notification Setting
        name: 'Notification Setting',
        url: '/admin/pmnsetting',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        // New User
        name: 'Create New User',
        url: '/admin/newuser',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        name: 'Connection',
        url: '/admin/connection',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        name: 'VDL Data Define',
        url: '/admin/catsDataDefine',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        name: 'VDL File Setting',
        url: '/admin/catsFileSetting',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      }
    ]
  }
];
