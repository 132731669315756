import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";

import * as UnitConversion from '../../models/unit-conversion';
import { UnitConversionService } from "../../services/unit-conversion.service";

@Component({
  selector: 'form-input-units',
	inputs: ['noLabel: noLabel'],
  template: `<form-input-select param = "unit_conversion" (click) = "updateMetric()"
							[(ngModel)] = "currentConversion" tag = "unit" optional = true [noLabel] = "noLabel">Unit Conversion</form-input-select>`
})
export class InputUnitsComponent implements OnInit, OnDestroy {
	// Keeps track of the current conversion metric
	private currentConversion: UnitConversion.UnitConversion;
	// Keeps track of the subscription of said metric
	private conversionSubscription: Subscription;
	// Determines if a label should be apparent
	private noLabel: boolean = false;
	
  constructor(private conversionService: UnitConversionService) { }

  ngOnInit() {
		this.currentConversion = UnitConversion.unitConversions[0];
		this.conversionSubscription = this.conversionService.getCurrentConversionSubscription().subscribe (response => {
			this.currentConversion = response;
		});
  }
	
	ngOnDestroy() {
		if (this.conversionSubscription) {
			this.conversionSubscription.unsubscribe();
		}
	}
	
	/** Updates the currently selected metric */
	public updateMetric () {
		console.log ('updated response');
		this.conversionService.updateConversion (this.currentConversion);
	}
 
}
