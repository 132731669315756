/* tslint:disable */
export interface Fleet {
	fleet_id?: number;
  name?: string;
	fleet_contact?: string;
  vehicle_type?: string;
  address?: string;
	fleet_logo?: any;
	create_time?: string;
	active_status?: string;
	time_zone?: string;
	modified_time?: string;
  fleet_desc?: string;
  time_offset?: number;
  fleet_functional_code?: string;
  default_unit_type?: string;
}

export const fleetStatus: string[] = [
	'online',
	'offline'
];

export const unitTypes: string[] = [
	'imperial',
	'metric'
];
