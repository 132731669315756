export interface UserAddressModel {
	address?: string;
	zip?: any;
	state?: State;
	city?: string;
}

export interface State {
	// The abbreviation of the state
	abbr: string;
	// The name of the state
	name: string;
}

// A list of states in the US / Central America
export const states: State[] = [
		{abbr: 'AL', name: 'Alabama'}, 
		{abbr: 'AK', name: 'Alaska'}, 
		{abbr: 'AS', name: 'American Samoa'}, 
		{abbr: 'AZ', name: 'Arizona'}, 
		{abbr: 'AR', name: 'Arkansas'}, 
		{abbr: 'CA', name: 'California'}, 
		{abbr: 'CO', name: 'Colorado'}, 
		{abbr: 'CT', name: 'Connecticut'}, 
		{abbr: 'DE', name: 'Delaware'}, 
		{abbr: 'DC', name: 'District of Columbia'}, 
		{abbr: 'FM', name: 'Federated States of Micronesia'}, 
		{abbr: 'FL', name: 'Florida'}, 
		{abbr: 'GA', name: 'Georgia'}, 
		{abbr: 'GU', name: 'Guam'}, 
		{abbr: 'HI', name: 'Hawaii'}, 
		{abbr: 'ID', name: 'Idaho'}, 
		{abbr: 'IL', name: 'Illinois'}, 
		{abbr: 'IN', name: 'Indiana'}, 
		{abbr: 'IA', name: 'Iowa'}, 
		{abbr: 'KS', name: 'Kansas'}, 
		{abbr: 'KY', name: 'Kentucky'}, 
		{abbr: 'LA', name: 'Louisiana'}, 
		{abbr: 'ME', name: 'Maine'}, 
		{abbr: 'MH', name: 'Marshall Islands'}, 
		{abbr: 'MD', name: 'Maryland'}, 
		{abbr: 'MA', name: 'Massachusetts'}, 
		{abbr: 'MI', name: 'Michigan'}, 
		{abbr: 'MN', name: 'Minnesota'}, 
		{abbr: 'MS', name: 'Mississippi'}, 
		{abbr: 'MO', name: 'Missouri'}, 
		{abbr: 'MT', name: 'Montana'}, 
		{abbr: 'NE', name: 'Nebraska'}, 
		{abbr: 'NV', name: 'Nevada'}, 
		{abbr: 'NH', name: 'New Hampshire'}, 
		{abbr: 'NJ', name: 'New Jersey'}, 
		{abbr: 'NM', name: 'New Mexico'}, 
		{abbr: 'NY', name: 'New York'}, 
		{abbr: 'NC', name: 'North Carolina'}, 
		{abbr: 'ND', name: 'North Dakota'}, 
		{abbr: 'MP', name: 'Northern Mariana Islands'}, 
		{abbr: 'OH', name: 'Ohio'}, 
		{abbr: 'OK', name: 'Oklahoma'}, 
		{abbr: 'OR', name: 'Oregon'}, 
		{abbr: 'PW', name: 'Palau'}, 
		{abbr: 'PA', name: 'Pennsylvania'}, 
		{abbr: 'PR', name: 'Puerto Rico'}, 
		{abbr: 'RI', name: 'Rhode Island'}, 
		{abbr: 'SC', name: 'South Carolina'}, 
		{abbr: 'SD', name: 'South Dakota'}, 
		{abbr: 'TN', name: 'Tennessee'}, 
		{abbr: 'TX', name: 'Texas'}, 
		{abbr: 'UT', name: 'Utah'}, 
		{abbr: 'VT', name: 'Vermont'}, 
		{abbr: 'VI', name: 'Virgin Islands'}, 
		{abbr: 'VA', name: 'Virginia'}, 
		{abbr: 'WA', name: 'Washington'}, 
		{abbr: 'WV', name: 'West Virginia'}, 
		{abbr: 'WI', name: 'Wisconsin'}, 
		{abbr: 'WY', name: 'Wyoming'}
]

/** Takes in a UserAddressModel and returns a string representation of it.
 *		@param input { UserAddressModel } - The address object to be parsed
 * 		@return a string representation of input, or a blank string if at least one field is empty.
 */
export function addressToString (input: UserAddressModel) {
		if (!checkEmpty (input.address) && (input.zip) && (input.state) && !checkEmpty (input.city)) {
			return input.address + "," + input.city + "," + input.state.abbr + "," + input.zip;
		}
		return "";
}

/** Transforms an address string into a user address object */
export function parseAddressString (address: string) {
	const tokens: string[] = address.split (',');
	const result: UserAddressModel = {
		address: tokens[0],
		city: tokens[1],
		state: getState (tokens[2]),
		zip: tokens[3]
	};
	return result;
}

/** Function used to return the state object associated with its abbreviation */
export function getState (abbr: string) {
	return states.find(st => {
		return st.abbr === abbr;
	});
}

/** Helper function that checks if a string is empty */
function checkEmpty(obj: string): boolean {
	return (!obj || obj === '');
}
