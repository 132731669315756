import {Component, OnInit, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

import {InputComponent} from '../input/input.component';
import {FormValidatorService} from '../../services/form-validator.service';
import {AuthenticationService} from '../../../../api/services/authentication.service';
import {AdminService} from '../../../../api/services/admin.service';
import * as UserParameters from '../../models/user-parameters';

export const CUSTOM_INPUT_SELECT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputSelectComponent),
	multi: true
};

@Component({
	selector: 'form-input-select',
	inputs: ['param: param', 'tag: tag', 'force: force', 'optional: optional', 'admin: admin', 'noLabel: noLabel', 'ioAdmin: ioAdmin'],
	template: `<div class = "form-spacing" *ngIf = "canShowAsAdmin ()">
								<label *ngIf = "!noLabel"> <ng-content></ng-content><span class = "required">{{'' | required: optional }}</span> </label>
								<select class = "form-control" id = {{param}} [(ngModel)] = "value" (blur) = "onBlur()" aria-describedby = "select-help" required>
									<option *ngFor = "let choice of choices" [ngValue] = choice>
											{{ getData(choice) | userParam | capitalize }}
									</option>
								</select>
								<small id="select-help" class="form-text text-muted">{{ description }}</small>
								<div [hidden] = "validator.isSuccessful(param)" class = "help-block">
										{{ validator.getMessage(param) }}
								</div>
						</div>`,
	styleUrls: ['../form-element.css'],
	providers: [CUSTOM_INPUT_SELECT_CONTROL_VALUE_ACCESSOR]
})

export class InputSelectComponent extends InputComponent {
	// Internal data structure to store the array of elements
	private choices: any;
	// An optional tag specifying which data member from choices are going to be outputted into options, if choices does point to an array of objects 
	private tag: string;

	constructor(private v: FormValidatorService,
		private a: AuthenticationService,
		private ad: AdminService) {
		super(v, a, ad);
	}

	ngOnInit() {
		super.ngOnInit();
		this.choices = UserParameters.getUserParameter (this.param).items;
	}

	/** @override to work with the input object */
	onBlur() {
		this.v.validateComponent(this.param, this.getData(this.innerValue));
		this.onTouchedCallback();
	}

	/** Helper function that extracts data from an object based on the existence of a tag
	 * 		@param data {any} - The data object */
	private getData(data: any) {
		if (this.tag && data) {
			return data[this.tag];
		}
		return data;
	}

}
