import {Component, OnInit, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

import {InputComponent} from '../input/input.component';
import {FormValidatorService} from '../../services/form-validator.service';
import {AuthenticationService} from '../../../../api/services/authentication.service';
import {AdminService} from '../../../../api/services/admin.service';

export const CUSTOM_INPUT_PHONE_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputPhoneComponent),
	multi: true
};

@Component({
	selector: 'form-input-phone',
	inputs: ['param: param', 'force: force', 'optional: optional', 'admin: admin', 'noLabel: noLabel' , 'autocomplete: autocomplete', 'ioAdmin: ioAdmin'],
	template: `<div class = "form-spacing" *ngIf = "canShowAsAdmin ()">
								<label *ngIf = "!noLabel"><ng-content></ng-content><span class = "required">{{'' | required: optional }}</span> </label>
								<input [(ngModel)] = "value" id = {{param}} class = "form-control" (blur) = "onBlur()" (keyup) = "value = phoneFormatter (value)" 
                       aria-describedby = "param-help" maxlength = 14 autocomplete = {{autocomplete}} required>
								<small id="param-help" class="form-text text-muted">{{ description }}</small>
								<div [hidden] = "validator.isSuccessful(param)" class = "help-block">
										{{ validator.getMessage(param) }}
								</div>
							</div>`,
	styleUrls: ['../form-element.css'],
	providers: [CUSTOM_INPUT_PHONE_CONTROL_VALUE_ACCESSOR]
})

export class InputPhoneComponent extends InputComponent {

	constructor(private v: FormValidatorService,
		private a: AuthenticationService,
		private ad: AdminService) {
		super(v, a, ad);
	}

	/** Formats a string into a phone number, if it exists
	 * 		@param phone - The phone number (as a string) to be formatted
	 * 		@return - The string formatted into a generic phone number */
	public phoneFormatter(phone: string) {
		let result: string = "";
		let firstPartition, thirdPartition: string;

		const rawNumber: string = ('' + phone).replace(/\D/g, '');

		// Extracts first partition
		if (rawNumber.length >= 1) {
			firstPartition = rawNumber.substr(0, 3);
			const match = firstPartition.match(/^(\d{1,3})$/);

			if (match) {
				result += '(' + match[1];
			}
		}

		// Extracts second partition
		if (rawNumber.length >= 4) {
			result += ') ' + rawNumber.substr(3, 3);
		}

		// Extracts third partition
		if (rawNumber.length >= 7) {
			thirdPartition = rawNumber.substr(6, 4);
			const match = thirdPartition.match(/^(\d{1,4})$/);

			if (match) {
				result += '-'
			}
			result += thirdPartition;
		}
		return result;
	}

	/** Overrides the validation method to automatically format the input field for phone */
	public validate(): void {
		this.writeValue(this.phoneFormatter(this.value));
		super.validate();
	}

}
