import { User } from "../../../api/models/user";

/* Defines a user search filter based on a user object's parameter*/
export interface FilterParameter {
	param: string;														// The filter parameter
	filter?: any;															// A filter function used to filter out the users
}

/* Defines a list of possible parameters to filter by */
export const filterBy: FilterParameter[] = 	[
	{ param: 'username', filter: filterByString },
  { param: 'name', filter: filterByString },
	{ param: 'email', filter: filterByString },
	{ param: 'address', filter: filterByString },
  { param: 'id', filter: filterByNumber },
	{ param: 'user_contact_id', filter: filterByNumber },
]

/** Filters the search result by string
 * 		@param{string} param - The component tag
 * 		@param{string} term - The search term */
export function filterByString (param: string, term: any) {
	return function (entity: User) {
		return entity[param].toLowerCase().includes (term.toLowerCase());
	}
}

/** Filters the search result by number * 
  * 		@param{string} param - The component tag
  * 		@param{string} term - The search term */
export function filterByNumber (param: string, term: any) {
	return function (entity: User) {
		return (entity[param] + '') === term;
	}
}